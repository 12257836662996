<template>
  <section
    class="
      bg-white
      sm:text-left
      shadow-xl
      rounded-xl
      sm:w-full sm:p-10 sm:h-full
      p-5
    "
  >
    <h3 class="text-base font-bold text-lg">
      {{ title }}
    </h3>
    <apexchart
      :id="idChart"
      type="bar"
      height="90%"
      width="100%"
      :options="chartOptions"
      :series="series"
      class="mt-6"
    ></apexchart>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Total de Quejas por Producto",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    favorableData: {
      type: [],
    },
    parcialyFavorableData: {
      type: [],
    },
    noFavorableData: {
      type: [],
    },
    datax: {
      type: [],
    },
    idChart: {
      type: String,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          redrawOnParentResize: true,
          stacked: true,
          height: "90%",
          width: "100%",
        },
        noData: {
          text: 'No hay datos para graficar',
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "24px",
            fontFamily: undefined,
          },
        },
        plotOptions: {
          bar: {
            horizontal: undefined,
            borderRadius: 20,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "13px",
            colors: ["#fff"],
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          fontSize: "16em",
          itemMargin: {
            horizontal: 20,
            vertical: 0,
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "7px",
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 6,
                },
              },
            },
          },
          {
            breakpoint: 300,
            options: {
              chart: {
                height: "100%",
                width: "100%",
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 2,
                },
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "7px",
                  },
                },
              },
              yaxis: [
                {
                  title: {
                    text: "",
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: "",
                  },
                },
              ],
            },
          },
        ],
        theme: {
          monochrome: {
            enabled: true,
            color: "#004884",
          },
        },
      },
    };
  },
  created() {
    this.series.push({
      name: "Favorable",
      data: this.processSeries(this.favorableData),
    });
    this.series.push({
      name: "No Favorable",
      data: this.processSeries(this.noFavorableData),
    });
    this.series.push({
      name: "Parcialmente Favorable",
      data: this.processSeries(this.parcialyFavorableData),
    });
    this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
    this.chartOptions.xaxis.categories = this.datax;
    this.chartOptions.dataLabels.offsetX = this.horizontal ? -10 : 0;
    this.chartOptions.dataLabels.offsetY = !this.horizontal ? 10 : 0;
  },
  methods: {
    processSeries(serie) {
      const mod = this.datax.length - serie.length;
      for (let index = 0; index < mod; index++) {
        serie.push(0);
      }
      return serie;
    },
  },
};
</script>
