<template>
  <section
    class="
      bg-white
      sm:text-left
      shadow-xl
      rounded-xl
      sm:w-full sm:p-10 sm:flex sm:flex-col sm:h-full
      p-2
    "
  >
    <h3 class="sm:text-xl font-bold text-base sm:mb-4">
      {{ title }}
    </h3>
    <apexchart
      type="pie"
      height="95%"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Pie Chart Title",
    },
    data: {
      type: [],
      required: true,
    },
    labels: {
      type: [],
      default: () => {
        return [
          " INCONFORMIDADES CON INTERVENCIÓN DE ENTES DE CONTROL",
          " INCONFORMIDADES SIN  INTERVENCIÓN DE ENTES DE CONTROL",
        ];
      },
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: "100%",
          width: "100%",
          type: "pie",
        },
        noData: {
          text: "No hay datos para graficar",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "24px",
            fontFamily: undefined,
          },
        },
        labels: [],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -7,
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "16em",
          itemMargin: {
            horizontal: 0,
            vertical: 7,
          },
          formatter: function (val, opts) {
            let total = opts.w.config.series[0] + opts.w.config.series[1];
            return (
              val +
              " ( " +
              ((opts.w.globals.series[opts.seriesIndex] * 100) / total).toFixed(
                2
              ) +
              " % )"
            );
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: "100%",
                height: "100%",
              },
              legend: {
                position: "bottom",
                fontSize: "12em",
              },
            },
          },
        ],
        dataLabels: {
          formatter(val, opts) {
            return opts.w.globals.series[opts.seriesIndex];
          },
        },

        theme: {
          monochrome: {
            enabled: true,
            color: "#004884",
          },
        },
      },
    };
  },
  created() {
    if (this.data?.length > 0 && this.labels?.length > 0) {
      this.series = this.data;
      this.chartOptions.labels = this.labels;
    }
  },
};
</script>